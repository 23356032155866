import Construction from "./components/Construction/Construction";

import './App.css';

function App() {
  return (
    <>
      <div className="mainContainer">
        <Construction />
      </div>
    </>
  );
}

export default App;
