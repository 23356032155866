
import { Header } from '../Header'
import Warning from '../../assets/icons/warning.svg'
import IconEmail from '../../assets/icons/email.svg'
import IconFacebook from '../../assets/icons/facebook.svg'
import IconInstagram from '../../assets/icons/insta.svg'
import IconLinkedin from '../../assets/icons/linkedin.svg'



import './Construction.css'

function Construction() {
  return (
    <>
      <Header />
      <div className="containerInfo">
        <img src={Warning} alt="Imagem de atenção" />
        <h2>Site em reformulação</h2>
        <p>Estamos passando por reformas em nosso site, logo estaremos de volta! Entre em contato com a RosaP através das nossas redes sociais!</p>

        <ul>
          <li>
            <a href="https://www.facebook.com/RosaP.com.br" target="_blank" rel="noreferrer">
              <img src={IconFacebook} alt="Facebook RosaP" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/rosapcadeiadeproducao/" target="_blank" rel="noreferrer">
              <img src={IconInstagram} alt="Instagram RosaP" />
            </a>
          </li>
          <li>
            <a href="mailto:fabio@rosap.com.br;marianaizahias@rosap.com.br" target="_blank" rel="noreferrer">
              <img src={IconEmail} alt="Email RosaP" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/rosap-cadeia-de-produ%C3%A7%C3%A3o-justa-1164341b6/" target="_blank" rel="noreferrer">
              <img src={IconLinkedin} alt="Linkedin RosaP" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Construction;
